<script lang="ts" setup></script>

<template>
  <div class="header-skeleton">
    <div class="header-skeleton__overlay container">
      <div class="header-skeleton__content">
        <v-skeleton size="large" w="480px" max-width="540px" />
        <v-skeleton size="small" w="400px" max-width="540px" />
        <v-skeleton size="large" h="100px" />
        <v-skeleton size="small" />
        <v-skeleton w="270px" max-width="540px" radius="32px" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .header-skeleton {
    height: 100%;
    position: relative;
    display: grid;
    &__overlay {
      background:
        linear-gradient(180deg, rgba(11, 11, 11, 0) 10.13%, #0b0b0b 85.45%),
        rgba(211, 211, 211, 0) 0.117px 0.563px / 115.723% 121.735% no-repeat;
      display: grid;
      align-content: flex-end;
      padding-bottom: 40px;
      z-index: 1;
    }

    &__content {
      position: relative;
      display: grid;
      gap: 16px;
      max-width: 540px;
      transition: 1s ease-out;
      transform: translateY(1rem);
    }
    @media (max-width: $retina) {
      padding-top: 238px;
    }
  }
</style>
